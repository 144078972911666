import { Box, Flex } from "@storyofams/react-ui";
import { SystemProps } from "@storyofams/react-ui";
import { getLinkProps } from "~lib";
import { Button } from "~components/common/Button";
import { DynamicSection } from "../blocks/Dynamic";
import { Image } from "./Image";
import { RichText } from "./RichText";
import { RichTextNH } from "./RichTextNH";

type ColumnsProps = {
  first?: boolean;
  content: any;
  parentComponent?: string;
} & SystemProps;

export const Stack = ({ content, first, parentComponent }: ColumnsProps) => {
  return (
    <Flex
      flexDirection={["column", "row"]}
      width="100%"
      mt={["20px", 0]}
      overflow={"hidden"}
      border={"1px solid grey200"}
      borderRadius={"8px"}
      maxWidth={content?.small ? "880px" : "none"}
      mb={0}
    >
      <Box
        width={["100%", content.ratio || "50%"]}
        flexDirection="column"
        backgroundColor={
          content?.isLeftBgEnabled ? content?.leftBg?.color : "none"
        }
      >
        {content?.left_column[0]?.component === "image" ? (
          <Image content={content?.left_column[0]}></Image>
        ) : (
          <Box
            height={"100%"}
            width={"100%"}
            display={"flex"}
            padding={["20px", "40px"]}
            flexDirection={"column"}
            justifyContent={
              content?.vertical_alignment
                ? content?.vertical_alignment
                : "center"
            }
          >
            <RichTextNH
              height={"max-content"}
              content={content?.left_column[0]}
            />
            {content?.left_column[0]?.button_text && (
              <Button
                variant="primary"
                width={["100%", "max-content"]}
                mt={[2, 3]}
                href={content?.left_column[0]?.button_link?.url}
                alignSelf={"start"}
                justifySelf={"start"}
              >
                {content?.left_column[0]?.button_text}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box
        order={[content?.right_column[0]?.component === "image" && -1, 1]}
        flex={1}
        width={["100%", "50%"]}
        backgroundColor={
          content?.isRightBgEnabled ? content?.rightBg?.color : "none"
        }
      >
        {content?.right_column[0]?.component === "image" ? (
          <Image content={content?.right_column[0]}></Image>
        ) : (
          <Box
            height={"100%"}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={
              content?.vertical_alignment
                ? content?.vertical_alignment
                : "center"
            }
            padding={["20px", "40px"]}
          >
            <RichTextNH
              height={"max-content"}
              content={content?.right_column[0]}
            />
            {content?.right_column[0]?.button_text && (
              <Button
                mt={[2, 3]}
                href={content?.right_column[0]?.button_link?.url}
                width={["100%", "max-content"]}
                alignSelf={"start"}
                variant="primary"
                justifySelf={"start"}
              >
                {content?.right_column[0]?.button_text}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Flex>
  );
};
